<template>
  <link rel="preload" as="image" href="https://d3dx13u2x4z410.cloudfront.net/pedro_022.jpg">
  <link rel="preload" as="image" href="https://d3dx13u2x4z410.cloudfront.net/nuno_02.jpg">
  <link rel="preload" as="image" href="https://d3dx13u2x4z410.cloudfront.net/tony02.jpg">
  <link rel="preload" as="image" href="https://d3dx13u2x4z410.cloudfront.net/renato_02.jpg">
  <link rel="preload" as="image" href="https://d3dx13u2x4z410.cloudfront.net/tiago_02.jpg">
    <link rel="preload" as="image" href="https://d3dx13u2x4z410.cloudfront.net/andre_02.jpg">
  <div>
    <div class="header-about h-screen">
      <nav-bar :fillBG="fillBG" :isTransparent="true" />
      <div class="square one"></div>
      <div class="square two"></div>
      <div class="square three"></div>
      <div class="square four"></div>
      <div class="square five"></div>
      <div class="square six"></div>
      <div class="square seven"></div>
      <div class="square eight"></div>
      <div class="square nine"></div>
      <div class="square ten"></div>
      <div class="square eleven"></div>
      <div class="square twelve"></div>
      <div class="square thirteen"></div>
      <div class="square fourteen"></div>
      <div class="square fifteen"></div>
      <div class="square sixteen"></div>
      <div class="square seventeen"></div>
      <div class="square heighteen"></div>
      <div
        class="
          flex flex-col
          justify-center
          h-4/6
          2xl:w-2/4
          xl:w-3/4
          w-11/12
          m-auto
        "
      >
        <div class="logo-box">
          <h1 class="title-contact text-4xl text-white mb-4">WELCOME TO</h1>
          <logo-white class="main-logo" />
        </div>
        <div class="text-box">
          <h2 class="our-title big">{{$t("about.mission_title")}}</h2>
          <h3 class="third-title">
            {{$t("about.mission_description")}}
          </h3>
        </div>
      </div>
    </div>
    <div style="margin: auto" class="2xl:w-2/4 xl:w-3/4 w-11/12">
      <h3 class="our-title">{{$t("about.story_title")}}</h3>
      <p class="story-description">
        {{$t("about.story_description")}}
      </p>
      <img class="team-photo" alt="the NAPPS team" src="https://d3dx13u2x4z410.cloudfront.net/team-photo.jpg" />
      <h3 class="our-title mt-52">{{$t("about.service_title")}}</h3>
      <p class="story-description">
        {{$t("about.service_description")}}
      </p>
      <img class="team-photo mb-52" src="https://d3dx13u2x4z410.cloudfront.net/team-photo02.jpg" alt="the NAPPS team" />
    </div>
    <div class="team-section">
      <div class="team-inner-section 2xl:w-2/4 xl:w-3/4 w-11/12 m-auto">
        <h3 class="our-title">{{$t("about.team_title")}}</h3>
        <div class="photo-section">
          <div class="photo-innersection">
            <div class="team-item">
              <div class="image-pedro" />
              <h4 class="team-title">Pedro Pontes</h4>
              <h5 class="team-subtitle">{{$t("about.role_tile_01")}}</h5>
            </div>
            <div class="team-item">
              <div class="image-nuno" />
              <h4 class="team-title">Nuno Batista</h4>
              <h5 class="team-subtitle">{{$t("about.role_tile_02")}}</h5>
            </div>
            <div class="team-item">
              <div class="image-renato" />
              <h4 class="team-title">Renato Mota</h4>
              <h5 class="team-subtitle">{{$t("about.role_tile_03")}}</h5>
            </div>
            <div class="team-item">
              <div class="image-tony" />
              <h4 class="team-title">Tony Silva</h4>
              <h5 class="team-subtitle">{{$t("about.role_tile_04")}}</h5>
            </div>
            <div class="team-item">
              <div class="image-tiago" />
              <h4 class="team-title">Tiago Lima</h4>
              <h5 class="team-subtitle">{{$t("about.role_tile_05")}}</h5>
            </div>
            <div class="team-item">
              <div class="image-andre" />
              <h4 class="team-title">André Pedroso</h4>
              <h5 class="team-subtitle">{{$t("about.role_tile_06")}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="quote-section mt-52 mb-52 2xl:w-2/4 xl:w-3/4 w-11/12 m-auto">
        <h1 class="quote-headline">
          {{$t("about.quote")}}
        </h1>
        <p class="quote-author">- Pedro Pontes, CEO da NAPPS</p>
      </div>
    <footer-2 />
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import NavBar from "@/components/Navbar/Main.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Logo from "@/components/Icons/Logo.vue";
import SpoilerAlert from "@/components/About/Spoiler_Alert.vue";
import ArrowContact from "../components/Icons/ArrowContact.vue";
import ContactBox from "../components/Icons/ContactBox.vue";
import LogoWhite from "../components/Icons/LogoWhite.vue";
import Footer2 from "@/components/Footer/Main.vue";

export default defineComponent({
  components: {
    Footer2,
    NavBar,
    SpoilerAlert,
    Logo,
    ArrowContact,
    ContactBox,
    LogoWhite,
    ScrollTrigger,
  },
  data() {
    return {
      tl: null,
      animated: false,
      fillBG: false,
    };
  },
  methods: {},
  unmounted() {
    window.scrollTo(0, 0);
    ScrollTrigger.getAll().forEach((element) => {
      element.kill();
    });
  },
  mounted() {
    ScrollTrigger.create({
      trigger: ".header-about",
      start: "top -10%",
      end: "bottom 150%",
      onEnter: () => {
        this.fillBG = true;
      },
      onEnterBack: () => {
        this.fillBG = false;
      },
    });
  },
});
</script>

<style scoped>
.main-logo {
  height: auto;
  width: 300px;
}

.our-title {
  color: #fbbc04;
  font-family: GothamMedium;
  font-size: 36px;
}
.third-title {
  color: white;
  font-family: GothamBold;
  font-size: 55px;
}

.big {
  font-size: 45px;
}

.logo-box {
  margin-top: 70px;
}

.text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.main-paragraph {
  color: #517da4;
  font-family: GothamBook;
  text-transform: uppercase;
  line-height: 55px;
  font-size: 22px;
  text-align: justify;
  text-justify: inter-word;
  letter-spacing: 2px;
}

.quote-headline {
  color: #517da4;
  font-family: GothamBold;
  text-transform: uppercase;
  font-size: 40px;
}

.quote-author {
  color: #fbbc04;
  font-family: GothamBold;
  font-size: 25px;
}

.story-title {
  color: #fbbc04;
  text-transform: uppercase;
  font-family: GothamMedium;
  font-size: 30px;
}

.story-description {
  color: #263238;
  font-family: GothamBook;
  font-size: 25px;
  line-height: 40px;
}

.team-section {
  background-color: #263238;
  padding: 100px 0;
  margin-top: 100px;
}

.photo-section {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.photo-innersection {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  gap: 20px;
}

.team-item {
  width: 31%;
}

.team-photo {
  margin-top: 50px;
}

.team-title {
  color: white;
  font-family: GothamMedium;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}

.team-subtitle {
  color: white;
  font-family: GothamBook;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
}

.side-photo-team {
  margin-bottom: 60px;
  width: 25%;
}

.headline-bottom {
  color: white;
  font-family: GothamBold, "sans-serif";
  font-size: 55px;
  text-align: center;
  padding: 100px;
  margin-right: 250px;
  margin-left: 250px;
}

.header-about {
  width: 100%;
  overflow: hidden;
  background: linear-gradient(
    to bottom,
    #263238 0%,
    #2632389c 50%,
    #517da400 100%
  );
}

.bottom-about {
  overflow: hidden;
  height: 150vh;
  margin-top: 150px;
  width: 100%;
  position: relative;
  background: linear-gradient(
    to bottom,
    #2b384e 0%,
    #2b384e 43%,
    #2b384e00 80%,
    #2b384e00 100%
  );
}

.title-contact {
  font-family: GothamBook;
}

.square {
  border: 1px solid #ffffffa2;
  border-radius: 4px;
  position: absolute;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 0.3s;
  opacity: 1;
}

.image-nuno {
  width: 100%;
  height: 500px;
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/nuno_01.jpg");
  background-position: center;
  background-size: cover;
}

.image-nuno:hover {
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/nuno_02.jpg");
}

.image-tony {
  height: 500px;
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/tony01.jpg");
  background-position: center;
  background-size: cover;
}

.image-tony:hover {
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/tony02.jpg");
}

.image-tiago {
  height: 500px;
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/tiago01.jpg");
  background-position: center;
  background-size: cover;
}

.image-tiago:hover {
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/tiago_02.jpg");
}

.image-renato {
  height: 500px;
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/renato_01.jpg");
  background-position: center;
  background-size: cover;
}

.image-renato:hover {
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/renato_02.jpg");
}

.image-pedro {
  height: 500px;
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/pedro_01.jpg");
  background-position: center;
  background-size: cover;
}

.image-pedro:hover {
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/pedro_022.jpg");
}

.image-andre {
  height: 500px;
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/andre_01.jpg");
  background-position: center;
  background-size: cover;
}

.image-andre:hover {
  background-image: url("https://d3dx13u2x4z410.cloudfront.net/andre_02.jpg");
}

@media only screen and (max-width: 1460px) {
  .phone-mockup {
    margin: auto;
    max-height: 60%;
  }
  .headline-bottom {
    padding: 0px;
    font-size: 40px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-right: 200px;
    margin-left: 200px;
  }
  .bottom-about {
    height: fit-content;
  }
  .main-paragraph {
    line-height: 40px;
    font-size: 20px;
  }
  .first-headline {
    font-size: 40px;
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .main-button {
    width: 20%;
  }
  .second-button {
    width: 20%;
  }
}

@media only screen and (max-width: 1024px) {
  .headline-bottom {
    margin-right: 100px;
    margin-left: 100px;
  }
  .sixteen {
    display: none;
  }
  .side-photo-team {
    display: none;
  }
  .photo-innersection {
    width: 100%;
  }
  .team-item {
    width: 47%;
  }
  .our-title {
    font-size: 25px;
  }
  .story-description {
    font-size: 20px;
    line-height: 35px;
  }
  .quote-headline {
    font-size: 30px;
  }
  .quote-author {
    font-size: 20px;
  }
  .title-contact {
    font-size: 30px !important;
    margin-bottom: 10px;
  }
  .logo-box > svg {
    width: 45%;
    margin-bottom: 20px;
  }
  .third-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 760px) {
  .quote-headline {
    font-size: 25px;
  }
  .team-item {
    width: 100%;
  }
  .square {
    display: none;
  }
  .main-logo {
    max-height: 100px;
    width: 60%;
  }
  .stat-label {
    font-size: 16px;
    margin-left: 20px;
  }
  .number-box {
    font-size: 45px;
  }
  .description-stat {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
  }
  .bottom-about {
    height: fit-content;
  }
  .phone-mockup {
    max-width: 70%;
    height: auto;
  }
  .headline-bottom {
    margin-right: 0px;
    margin-left: 0px;
    font-size: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .stat-box {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .first-headline {
    font-size: 35px;
    text-align: center;
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
}

@media only screen and (max-width: 460px) {
  .title-contact {
    font-size: 40px;
  }
  .main-logo {
    width: 80%;
  }
  .main-paragraph {
    line-height: 35px;
    font-size: 18px;
    text-align: center;
  }
  .first-headline {
    font-size: 30px;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .description-stat {
    font-size: 15px;
  }
  .stat-label {
    font-size: 14px;
    margin-left: 15px;
  }
  .main-button {
    width: 80%;
  }
  .second-button {
    width: 80%;
  }
}

.one {
  height: 50px;
  width: 50px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: one;
}

.one-bottom {
  height: 50px;
  width: 50px;
  top: 23%;
  left: 20%;
  transform: rotate(20deg);
}

.two {
  height: 90px;
  width: 90px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: two;
}

.two-bottom {
  height: 90px;
  width: 90px;
  top: 20%;
  left: 80%;
  transform: rotate(20deg);
}

.three {
  height: 20px;
  width: 20px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: three;
}

.three-bottom {
  height: 20px;
  width: 20px;
  top: 3%;
  left: 20%;
  transform: rotate(20deg);
}

.four {
  opacity: 0;
  height: 80px;
  width: 80px;
  top: 33%;
  left: 50%;
  transform: rotate(120deg);
  animation-name: four;
}

.four-bottom {
  height: 20px;
  width: 20px;
  top: 6%;
  left: 80%;
  transform: rotate(120deg);
}

.five {
  opacity: 0;
  height: 50px;
  width: 50px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  animation-name: five;
}

.five-bottom {
  height: 20px;
  width: 20px;
  top: 20%;
  left: 8%;
  transform: rotate(120deg);
}

.six {
  height: 25px;
  width: 25px;
  top: 33%;
  left: 50%;
  transform: rotate(80deg);
  opacity: 0;
  animation-name: six;
}

.six-bottom {
  height: 100px;
  width: 100px;
  top: 35%;
  left: 90%;
  transform: rotate(120deg);
}

.seven {
  height: 85px;
  width: 85px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: seven;
}

.seven-bottom {
  height: 6px;
  width: 6px;
  top: 6%;
  left: 51%;
  border-radius: 2px;
  transform: rotate(120deg);
}

.eight {
  height: 90px;
  width: 90px;
  top: 33%;
  left: 50%;
  transform: rotate(220deg);
  opacity: 0;
  animation-name: eight;
}

.eight-bottom {
  height: 20px;
  width: 20px;
  top: 2%;
  left: 65%;
  transform: rotate(120deg);
}

.nine {
  height: 15px;
  width: 15px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: nine;
}

.nine-bottom {
  height: 20px;
  width: 20px;
  top: 4%;
  left: 5%;
  transform: rotate(120deg);
}

.ten {
  height: 15px;
  width: 15px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: ten;
}

.ten-bottom {
  height: 20px;
  width: 20px;
  top: 4%;
  right: 5%;
  transform: rotate(120deg);
}

.eleven {
  border-radius: 1px;
  height: 6px;
  width: 6px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: eleven;
}

.eleven-bottom {
  height: 100px;
  width: 100px;
  top: 30%;
  left: 2%;
  transform: rotate(120deg);
}

.twelve {
  border-radius: 1px;
  height: 5px;
  width: 5px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: twelve;
}

.twelve-bottom {
  border-radius: 1px;
  height: 5px;
  width: 5px;
  top: 3%;
  left: 75%;
  transform: rotate(20deg);
}

.thirteen {
  border-radius: 2px;
  height: 6px;
  width: 6px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: thirteen;
}

.thirteen-bottom {
  border-radius: 1px;
  height: 5px;
  width: 5px;
  top: 5%;
  left: 35%;
  transform: rotate(20deg);
}

.fourteen {
  border-radius: 1px;
  height: 5px;
  width: 5px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: fourteen;
}

.fourteen-bottom {
  border-radius: 1px;
  height: 5px;
  width: 5px;
  top: 2%;
  left: 15%;
  transform: rotate(20deg);
}

.fifteen {
  border-radius: 1px;
  height: 8px;
  width: 8px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: fifteen;
}

.fifteen-bottom {
  border-radius: 1px;
  height: 5px;
  width: 5px;
  top: 20%;
  left: 30%;
  transform: rotate(20deg);
}

.sixteen {
  height: 80px;
  width: 80px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: sixteen;
}

.sixteen-bottom {
  border-radius: 1px;
  height: 5px;
  width: 5px;
  top: 22%;
  left: 61%;
  transform: rotate(20deg);
}

.seventeen {
  height: 70px;
  width: 70px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: seventeen;
}

.seventeen-bottom {
  border-radius: 1px;
  height: 5px;
  width: 5px;
  top: 5%;
  left: 61%;
  transform: rotate(20deg);
}

.heighteen {
  height: 70px;
  width: 70px;
  top: 33%;
  left: 50%;
  transform: rotate(20deg);
  opacity: 0;
  animation-name: heighteen;
}

@keyframes one {
  40% {
    opacity: 1;
  }
  70% {
    opacity: 1;
    transform: rotate(160deg);
    left: 16%;
    top: 30%;
  }
  80% {
    opacity: 1;
    transform: rotate(180deg);
    left: 13%;
    top: 30%;
  }
  100% {
    opacity: 1;
    transform: rotate(200deg);
    left: 10%;
    top: 30%;
  }
}

@keyframes two {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(160deg);
    top: 54%;
    left: 70%;
  }
  100% {
    opacity: 1;
    transform: rotate(170deg);
    top: 56%;
    left: 72%;
  }
}

@keyframes three {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(160deg);
    top: 16%;
    left: 68%;
  }
  100% {
    opacity: 1;
    transform: rotate(170deg);
    top: 15%;
    left: 70%;
  }
}

@keyframes four {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(160deg);
    top: 43%;
    left: 25%;
  }
  100% {
    opacity: 1;
    transform: rotate(170deg);
    top: 45%;
    left: 17%;
  }
}

@keyframes five {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(160deg);
    top: 21%;
    left: 88%;
  }
  100% {
    opacity: 1;
    transform: rotate(170deg);
    top: 20%;
    left: 90%;
  }
}

@keyframes six {
  40% {
    opacity: 1;
  }
  85% {
    opacity: 1;
    transform: rotate(160deg);
    top: 69%;
    left: 61%;
  }
  100% {
    opacity: 1;
    transform: rotate(170deg);
    top: 72%;
    left: 62%;
  }
}

@keyframes seven {
  40% {
    opacity: 1;
  }
  70% {
    opacity: 1;
    transform: rotate(160deg);
    top: 63%;
    left: 35%;
  }
  80% {
    opacity: 1;
    transform: rotate(170deg);
    top: 64%;
    left: 35%;
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
    top: 65%;
    left: 35%;
  }
}

@keyframes eight {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(170deg);
    top: 58%;
    left: 9%;
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
    top: 60%;
    left: 7%;
  }
}

@keyframes nine {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(170deg);
    top: 12%;
    left: 32%;
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
    top: 10%;
    left: 30%;
  }
}

@keyframes ten {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(170deg);
    top: 10%;
    left: 23%;
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
    top: 8%;
    left: 22%;
  }
}

@keyframes eleven {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(170deg);
    top: 13%;
    left: 63%;
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
    top: 12%;
    left: 64%;
  }
}

@keyframes twelve {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(170deg);
    top: 15%;
    left: 85%;
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
    top: 14%;
    left: 87%;
  }
}

@keyframes thirteen {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(170deg);
    top: 8%;
    left: 53%;
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
    top: 6%;
    left: 55%;
  }
}

@keyframes fourteen {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(170deg);
    top: 10%;
    left: 73%;
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
    top: 9%;
    left: 75%;
  }
}

@keyframes fifteen {
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: rotate(170deg);
    top: 18%;
    left: 38%;
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
    top: 17%;
    left: 40%;
  }
}

@keyframes sixteen {
  40% {
    opacity: 1;
  }
  70% {
    transform: rotate(160deg);
    opacity: 1;
    top: 68%;
    left: 88%;
  }
  80% {
    transform: rotate(170deg);
    opacity: 1;
    top: 71%;
    left: 90%;
  }
  100% {
    transform: rotate(180deg);
    opacity: 1;
    top: 75%;
    left: 92%;
  }
}

@keyframes seventeen {
  40% {
    opacity: 1;
  }
  70% {
    transform: rotate(160deg);
    opacity: 1;
    top: 35%;
    left: 76%;
  }
  80% {
    transform: rotate(170deg);
    opacity: 1;
    top: 35%;
    left: 78%;
  }
  100% {
    transform: rotate(180deg);
    opacity: 1;
    top: 35%;
    left: 80%;
  }
}

@keyframes heighteen {
  100% {
    transform: rotate(170deg);
    left: 24%;
    top: 40%;
  }
}
</style>
