<template>
  <div class="main flex flex-col">
    <div class="bg-mainBrand text-white w-full flex flex-col items-center p-20">
      <nav-bar
        :fillBG="fillBG"
        :isTransparent="true"
        style="background: none"
      />

      <div id="main-section" class="flex flex-col">
        <h1
          class="
            font-gothambold
            text-3xl
            sm:text-4xl
            tracking-tight
            text-center
            md:text-left
          "
        >
          {{ $t("team.title") }}
        </h1>

        <div
          class="
            container
            flex flex-col
            lg:flex-row
            items-center
            gap-20
            w-full
            justify-between
            mt-12
            text-mainBrand
          "
        >
          <router-link
            to="/team/pedro-pontes"
            class="
              relative
              mx-auto
              md:mx-0
              w-72
              group
              overflow-hidden
              transition-shadow
              hover:shadow-lg
            "
            style="height: 32em"
          >
            <img
              class="h-full w-full object-cover"
              src="@/assets/imgs/pedropontes.png"
              alt="Pedro Pontes headshot"
            />
            <div
              class="
                absolute
                p-3
                bottom-space
                w-full
                h-64
                transition-transform
                transform
                bg-yellowBrand
                origin-bottom
                group-hover:-translate-y-40
              "
            >
              <h1 class="font-gothammedium">Pedro Pontes</h1>
              <p class="font-gothambook italic text-sm opacity-90">
                {{ $t("team.pedro_pontes") }}
              </p>
              <hr class="border-mainBrand mt-8 opacity-90" />
              <p class="mt-5 text-sm font-gothambook">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatem vitae ipsam, illum nulla nemo maiores.
              </p>
              <div class="flex items-center gap-4 mt-4 mb-4">
                <h3 class="font-gothammedium text-md">{{ $t("team.read_bio") }}</h3>
                <svg
                class="w-12"
                  width="73"
                  height="20"
                  viewBox="0 0 73 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.8839 10.8839C72.372 10.3957 72.372 9.60427 71.8839 9.11612L63.9289 1.16117C63.4408 0.67301 62.6493 0.67301 62.1612 1.16117C61.673 1.64932 61.673 2.44078 62.1612 2.92893L69.2322 10L62.1612 17.0711C61.673 17.5592 61.673 18.3507 62.1612 18.8388C62.6493 19.327 63.4408 19.327 63.9289 18.8388L71.8839 10.8839ZM0 11.25L71 11.25V8.75L0 8.75L0 11.25Z"
                    fill="#263238"
                  />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            to="/team/nuno-batista"
            class="
              relative
              mx-auto
              md:mx-0
              w-72
              group
              overflow-hidden
              transition-shadow
              hover:shadow-lg
            "
            style="height: 32em"
          >
            <img
              class="h-full w-full object-cover"
              src="@/assets/imgs/nunobatista.png"
              alt="Nuno Batista headshot"
            />
            <div
              class="
                absolute
                p-3
                bottom-space
                w-full
                h-64
                transition-transform
                transform
                bg-yellowBrand
                origin-bottom
                group-hover:-translate-y-40
              "
            >
              <h1 class="font-gothammedium">Nuno Batista</h1>
              <p class="font-gothambook italic text-sm opacity-90">
                {{ $t("team.nuno_batista") }}
              </p>
              <hr class="border-mainBrand opacity-90 mt-8" />
              <p class="mt-5 text-sm font-gothambook">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatem vitae ipsam, illum nulla nemo maiores.
              </p>
              <div class="flex items-center gap-4 mt-4 mb-4">
                <h3 class="font-gothammedium text-md">{{ $t("team.read_bio") }}</h3>
                <svg
                class="w-12"
                  width="73"
                  height="20"
                  viewBox="0 0 73 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.8839 10.8839C72.372 10.3957 72.372 9.60427 71.8839 9.11612L63.9289 1.16117C63.4408 0.67301 62.6493 0.67301 62.1612 1.16117C61.673 1.64932 61.673 2.44078 62.1612 2.92893L69.2322 10L62.1612 17.0711C61.673 17.5592 61.673 18.3507 62.1612 18.8388C62.6493 19.327 63.4408 19.327 63.9289 18.8388L71.8839 10.8839ZM0 11.25L71 11.25V8.75L0 8.75L0 11.25Z"
                    fill="#263238"
                  />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            to="/team/renato-mota"
            class="
              relative
              mx-auto
              md:mx-0
              w-72
              group
              overflow-hidden
              transition-shadow
              hover:shadow-lg
            "
            style="height: 32em"
          >
            <img
              class="h-full w-full object-cover"
              src="@/assets/imgs/renatomota.png"
              alt="Renato Mota headshot"
            />
            <div
              class="
                absolute
                p-3
                bottom-space
                w-full
                h-64
                transition-transform
                transform
                bg-yellowBrand
                origin-bottom
                group-hover:-translate-y-40
              "
            >
              <h1 class="font-gothammedium">Renato Mota</h1>
              <p class="font-gothambook italic text-sm opacity-90">
                {{ $t("team.renato_mota") }}
              </p>
              <hr class="border-mainBrand mt-8 opacity-90" />
              <p class="mt-5 text-sm font-gothambook">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatem vitae ipsam, illum nulla nemo maiores.
              </p>
              <div class="flex items-center gap-4 mt-4 mb-4">
                <h3 class="font-gothammedium text-md">{{ $t("team.read_bio") }}</h3>
                <svg
                class="w-12"
                  width="73"
                  height="20"
                  viewBox="0 0 73 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.8839 10.8839C72.372 10.3957 72.372 9.60427 71.8839 9.11612L63.9289 1.16117C63.4408 0.67301 62.6493 0.67301 62.1612 1.16117C61.673 1.64932 61.673 2.44078 62.1612 2.92893L69.2322 10L62.1612 17.0711C61.673 17.5592 61.673 18.3507 62.1612 18.8388C62.6493 19.327 63.4408 19.327 63.9289 18.8388L71.8839 10.8839ZM0 11.25L71 11.25V8.75L0 8.75L0 11.25Z"
                    fill="#263238"
                  />
                </svg>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div
      class="
        px-5
        my-24
        container
        font-gothambold
        text-mainBrand text-center text-2xl
        self-center
      "
    >
      <h2 class="md:text-2xl lg:text-4xl">
        OUR LEADERSHIP TEAM IS ALWAYS CONCERNED WITH EVERY DETAIL.
      </h2>
      <h2 class="md:text-2xl lg:text-4xl">THEY ARE FOCUSED ON YOU!</h2>
    </div>

    <footer2 />
  </div>
</template>

<script>
import NavBar from "@/components/Navbar/Main.vue";
import Footer2 from "@/components/Footer/Main.vue";

import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  components: {
    NavBar,
    Footer2,
    ScrollTrigger,
  },
  data() {
    return {
      fillBG: false,
    };
  },
  mounted() {
    ScrollTrigger.create({
      trigger: "#main-section",
      start: "top 6%",
      end: "bottom 130%",
      onEnter: () => {
        this.fillBG = true;
      },
      onEnterBack: () => {
        this.fillBG = false;
      },
    });
  },
  unmounted() {
    window.scrollTo(0, 0);
    ScrollTrigger.getAll().forEach((element) => {
      element.kill();
    });
  },
};
</script>

<style scoped>
.bottom-space {
  bottom: -10.5rem;
}
</style>
