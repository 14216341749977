<template>
  <div class="flex flex-col bg-mainBrand">
    <nav-bar :fillBG="fillBG" :isTransparent="true" style="background: none" />
    <div class="w-full mt-6">
      <div class="mx-auto container">
        <div class="box-go-back" style="width: fit-content">
          <router-link style="width: fit-content" to="/team">
            <div class="flex flex-row items-center gap-4">
              <svg
                width="23"
                height="23"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="19.5"
                  cy="19.5"
                  r="18"
                  transform="rotate(-180 19.5 19.5)"
                  stroke="#FBBC04"
                  stroke-width="3"
                />
                <path
                  d="M28.5122 20.5C29.3406 20.5 30.0122 19.8284 30.0122 19C30.0122 18.1716 29.3406 17.5 28.5122 17.5V20.5ZM10.9395 17.9393C10.3537 18.5251 10.3537 19.4749 10.9395 20.0607L20.4854 29.6066C21.0712 30.1924 22.0209 30.1924 22.6067 29.6066C23.1925 29.0208 23.1925 28.0711 22.6067 27.4853L14.1215 19L22.6067 10.5147C23.1925 9.92893 23.1925 8.97919 22.6067 8.3934C22.0209 7.80761 21.0712 7.80761 20.4854 8.3934L10.9395 17.9393ZM28.5122 17.5L12.0001 17.5V20.5L28.5122 20.5V17.5Z"
                  fill="#FBBC04"
                />
              </svg>
              <span class="go-back">NAPPS LEADERSHIP TEAM</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div
      id="main-section"
      class="
        w-full
        bg-mainBrand
        text-white
        p-10
        md:p-20
        pt-20
        flex flex-col
        justify-end
      "
    >
      <div
        class="
          main-brand
          self-center
          bg-mainBrand
          flex
          justify-between
          md:h-48
          container
        "
      >
        <div class="self-end">
          <h1 class="font-gothambold text-4xl md:text-5xl w-48 md:w-auto">
            Nuno Batista
          </h1>
          <p class="font-gothambook italic text-sm md:text-2xl opacity-90 mt-5">
            {{ $t("team.nuno_batista") }}
          </p>
        </div>

        <div
          class="
            h-32
            w-36
            self-end
            transform
            md:translate-y-40 md:w-auto md:h-auto
            flex-shrink-0
            headshot
          "
        >
          <img
            class="md:h-96 md:w-72 object-cover flex-shrink-0"
            src="@/assets/imgs/nunobatista.png"
            alt="Pedro Pontes Headshot"
          />
        </div>
      </div>
    </div>

    <div class="bg-gray-100 w-full p-10 md:p-20 pt-20 flex flex-col">
      <div
        class="
          self-center
          flex-shrink-0
          text-mainBrand
          flex flex-col
          md:flex-row
          justify-between
          bg-gray-100
          container
          md:py-10
        "
      >
        <div class="w-full md:w-3/4">
          <h3 class="font-gothammedium text-2xl">BIOGRAPHY</h3>
          <p class="font-gothambook mt-5 md:mr-20 leading-8 opacity-90">
            A NAPPS iniciou quando dois amigos entenderam que havia no mercado a
            necessidade de uma aplicação com custos mais reduzidos e um maior
            apoio ao cliente – Que possibilitaria ao utilizador maior conversão,
            a longo prazo. Assim criamos uma plataforma que dá o poder a milhões
            de clientes em todo o mundo para iniciar e fazer crescer os seus
            negócios com a nossa tecnologia. Fundada em Fevereiro de 2021 e
            sediada em Portugal com escritórios no Porto, a NAPPS é 100%
            propriedade dos fundadores.
          </p>
        </div>
        <div class="md:w-72 mt-10 md:mt-0 flex-shrink-0">
          <a
            aria-label="Download Headshot"
            target="_blank"
            href="https://nappscdn.s3.eu-west-3.amazonaws.com/nuno_01.jpg"
            @click.prevent="downloadItem('Headshot-Nuno-Batista', 'jpg')"
            class="
              flex
              justify-between
              items-center
              p-4
              bg-white
              rounded-lg
              shadow
              hover:shadow-lg
              transition-shadow
              mb-5
            "
          >
            <div class="flex flex-col">
              <h1 class="font-gothambold">Headshot</h1>
              <p
                v-if="blobImage != null && blobImage != undefined"
                class="font-gothambook"
              >
                JPG {{ imageSize }}
              </p>
            </div>
            <svg
              class="w-6 h-6"
              width="30"
              height="24"
              viewBox="0 0 30 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.6129 8.88C24.2229 3.91821 20.0604 0 15 0C10.6714 0 6.95571 2.81143 5.75679 6.91179C2.33679 8.09893 0 11.3389 0 15C0 19.7261 3.84536 23.5714 8.57143 23.5714H22.5C26.6357 23.5714 30 20.2071 30 16.0714C30 12.6975 27.7821 9.80143 24.6129 8.88ZM22.5 21.4286H8.57143C5.02607 21.4286 2.14286 18.5454 2.14286 15C2.14286 12.0943 4.10679 9.54214 6.91821 8.79429C7.30393 8.69143 7.60071 8.38286 7.68857 7.99393C8.44929 4.60393 11.5243 2.14286 15 2.14286C18.9579 2.14286 22.4957 5.27571 22.4957 9.73821C22.4957 10.2621 22.8739 10.7089 23.3904 10.7946C25.9789 11.2286 27.8571 13.4475 27.8571 16.0714C27.8571 19.0254 25.4539 21.4286 22.5 21.4286V21.4286Z"
                fill="#263238"
              />
              <path
                d="M9.9568 14.6873C9.53787 14.2683 9.53787 13.5912 9.9568 13.1723C10.3757 12.7533 11.0529 12.7533 11.4718 13.1723L13.9286 15.629L13.9286 7.50118C13.9286 6.90975 14.4086 6.42975 15 6.42975C15.5914 6.42975 16.0714 6.90975 16.0714 7.50118V15.629L18.5282 13.1723C18.9472 12.7533 19.6243 12.7533 20.0432 13.1723C20.4622 13.5912 20.4622 14.2683 20.0432 14.6873L15.7575 18.973C15.3397 19.3908 14.6561 19.3876 14.2414 18.973L9.9568 14.6873Z"
                fill="#263238"
              />
            </svg>
          </a>

          <a
            aria-label="Download Biography"
            target="_blank"
            href="https://docs.google.com/document/d/19GuUi-426VQrk2DNf4Ru3gi9XgbUOAsIHDfxbjfHnaI/edit?usp=sharing"
            download
            @click.prevent="downloadItem('Biografia Nuno Batista', 'pdf')"
            class="
              flex
              justify-between
              items-center
              p-4
              bg-white
              rounded-lg
              shadow
              hover:shadow-lg
              transition-shadow
              mb-5
            "
          >
            <div class="flex flex-col">
              <h1 class="font-gothambold">Biography</h1>
              <p
                v-if="blobPdf != null && blobPdf != undefined"
                class="font-gothambook"
              >
                PDF {{ pdfSize }}
              </p>
            </div>
            <svg
              class="w-6 h-6"
              width="30"
              height="24"
              viewBox="0 0 30 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.6129 8.88C24.2229 3.91821 20.0604 0 15 0C10.6714 0 6.95571 2.81143 5.75679 6.91179C2.33679 8.09893 0 11.3389 0 15C0 19.7261 3.84536 23.5714 8.57143 23.5714H22.5C26.6357 23.5714 30 20.2071 30 16.0714C30 12.6975 27.7821 9.80143 24.6129 8.88ZM22.5 21.4286H8.57143C5.02607 21.4286 2.14286 18.5454 2.14286 15C2.14286 12.0943 4.10679 9.54214 6.91821 8.79429C7.30393 8.69143 7.60071 8.38286 7.68857 7.99393C8.44929 4.60393 11.5243 2.14286 15 2.14286C18.9579 2.14286 22.4957 5.27571 22.4957 9.73821C22.4957 10.2621 22.8739 10.7089 23.3904 10.7946C25.9789 11.2286 27.8571 13.4475 27.8571 16.0714C27.8571 19.0254 25.4539 21.4286 22.5 21.4286V21.4286Z"
                fill="#263238"
              />
              <path
                d="M9.9568 14.6873C9.53787 14.2683 9.53787 13.5912 9.9568 13.1723C10.3757 12.7533 11.0529 12.7533 11.4718 13.1723L13.9286 15.629L13.9286 7.50118C13.9286 6.90975 14.4086 6.42975 15 6.42975C15.5914 6.42975 16.0714 6.90975 16.0714 7.50118V15.629L18.5282 13.1723C18.9472 12.7533 19.6243 12.7533 20.0432 13.1723C20.4622 13.5912 20.4622 14.2683 20.0432 14.6873L15.7575 18.973C15.3397 19.3908 14.6561 19.3876 14.2414 18.973L9.9568 14.6873Z"
                fill="#263238"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>

    <footer2 class="bg-white" />
  </div>
</template>

<script>
import NavBar from "@/components/Navbar/Main.vue";
import Footer2 from "@/components/Footer/Main.vue";
import axios from "axios";

import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  components: {
    NavBar,
    Footer2,
    ScrollTrigger,
  },
  data() {
    return {
      fillBG: false,
      imageLink: "https://nappscdn.s3.eu-west-3.amazonaws.com/nuno_01.jpg",
      fileLink:
        "https://docs.google.com/document/d/19GuUi-426VQrk2DNf4Ru3gi9XgbUOAsIHDfxbjfHnaI/edit?usp=sharing",
      blobImage: null,
      imageSize: null,
      blobPdf: null,
      pdfSize: null,
    };
  },
  methods: {
    downloadItem(name, type) {
      const link = document.createElement("a");
      if (type === "jpg") {
        link.href = URL.createObjectURL(this.blobImage);
      } else {
        link.href = URL.createObjectURL(this.blobPdf);
      }
      link.download = name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
  mounted() {
    axios
      .get("https://nappscdn.s3.eu-west-3.amazonaws.com/nuno_01.jpg", {
        responseType: "blob",
      })
      .then((response) => {
        this.blobImage = new Blob([response.data], { type: "image/jpeg" });
        this.imageSize = this.formatBytes(this.blobImage.size);
      })
      .catch(console.error);

    axios
      .get(
        "https://nappscdn.s3.eu-west-3.amazonaws.com/Carta+dos+Fundadores.pdf",
        { responseType: "blob" }
      )
      .then((response) => {
        this.blobPdf = new Blob([response.data], { type: "application/pdf" });
        this.pdfSize = this.formatBytes(this.blobPdf.size);
      })
      .catch(console.error);

    ScrollTrigger.create({
      trigger: "#main-section",
      start: "top 6%",
      end: "bottom 130%",
      onEnter: () => {
        this.fillBG = true;
      },
      onEnterBack: () => {
        this.fillBG = false;
      },
    });
  },
  unmounted() {
    window.scrollTo(0, 0);
    ScrollTrigger.getAll().forEach((element) => {
      element.kill();
    });
  },
};
</script>

<style scoped>
.go-back {
  color: #979494;
  font-family: GothamMedium;
  line-height: 16px;
}
@media only screen and (max-width: 600px) {
  .box-go-back {
    margin: auto;
  }
  .main-brand {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .headshot {
    width: 16rem;
    height: 16rem;
  }
  .self-end {
    align-self: center;
  }
  .self-end > h1 {
    width: 100%;
    margin-right: 0px;
  }
  .self-end > p {
    margin-bottom: 20px;
  }
}
</style>