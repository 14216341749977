<template>
<svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" width="1024" height="685.85" viewBox="0 0 1024 685.85">
  <g id="Grupo_103" data-name="Grupo 103">
    <g id="Spoiler_Alert" data-name="Spoiler Alert" style="opacity: 0.699999988079071;isolation: isolate">
      <text transform="translate(89.4 307.35)" style="isolation: isolate;font-size: 190px;fill: #afcad8;font-family: Helvetica">SPOILER </text>
      <text transform="translate(100.53 557.35)" style="isolation: isolate;font-size: 250px;fill: #afcad8;font-family: Helvetica">ALE<tspan x="472.53" y="0" style="letter-spacing: -0.017578125em">RT</tspan></text>
    </g>
    <g id="Spoiler_Alert-2" data-name="Spoiler Alert-2" style="isolation: isolate">
      <text transform="translate(80.18 283.35)" style="isolation: isolate;font-size: 190px;fill: #fe9d9c;font-family: Helvetica">SPOILER</text>
      <text transform="translate(903.82 283.35)" style="isolation: isolate;font-size: 330px;fill: #fe9d9c;font-family: Helvetica"> </text>
      <text transform="translate(91.3 533.35)" style="isolation: isolate;font-size: 250px;fill: #fe9d9c;font-family: Helvetica">ALE<tspan x="472.53" y="0" style="letter-spacing: -0.017578125em">RT</tspan></text>
    </g>
    <path id="Caminho_209" data-name="Caminho 209" d="M93,89.5h981" transform="translate(-62.5 12.35)" style="fill: none;stroke: #fe9d9c;stroke-linecap: round;stroke-width: 15px;opacity: 0.699999988079071;isolation: isolate"/>
    <path id="Caminho_210" data-name="Caminho 210" d="M70,648h981" transform="translate(-62.5 12.35)" style="fill: none;stroke: #afcad8;stroke-linecap: round;stroke-width: 15px;opacity: 0.699999988079071;isolation: isolate"/>
    <path id="Caminho_211" data-name="Caminho 211" d="M98,666h981" transform="translate(-62.5 12.35)" style="fill: none;stroke: #fe9d9c;stroke-linecap: round;stroke-width: 15px;opacity: 0.699999988079071;isolation: isolate"/>
    <path id="Caminho_212" data-name="Caminho 212" d="M70,71.5h981" transform="translate(-62.5 12.35)" style="fill: none;stroke: #afcad8;stroke-linecap: round;stroke-width: 15px;opacity: 0.699999988079071;isolation: isolate"/>
  </g>
</svg>

</template>
<script>
export default {
    name: 'Spoiler_Alert',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    },
}
</script>