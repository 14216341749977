<template>
  <div class="main text-mainBrand">
    <nav-bar :fillBG="fillBG" style="background: none" />

    <div
      id="main-section"
      class="
        flex flex-col
        gap-5
        py-28
        md:py-48
        items-start
        md:items-center
        justify-center
        bg-gray-50
        px-5
      "
    >
      <h3 class="text-yellow-500 font-gothammedium text-sm">
        {{ $t("press.subtitle") }}
      </h3>
      <h1 class="font-gothambold uppercase text-mainBrand text-5xl">
        {{ $t("press.title") }}
      </h1>
      <p
        class="text-mainBrand font-gothambook md:text-center text-lg max-w-3xl"
      >
        {{ $t("press.description") }}
      </p>
      <router-link to="/contactus">
        <button class="main-button">
          {{ $t("press.button") }}
        </button>
      </router-link>
    </div>

    <div class="bg-white flex flex-col">
      <div class="container self-center">
        <h2
          class="
            text-mainBrand
            font-gothammedium
            text-3xl text-center
            md:text-left
            ml-5
            mt-10
            md:ml-10 md:mt-10
          "
        >
          {{ $t("press.our_company") }}
        </h2>

        <div
          class="
            grid
            container
            self-center
            overflow-hidden
            grid-cols-1
            md:grid-cols-2
            grid-rows-4
            gap-10
            p-5
            md:p-20 md:pt-2
          "
        >
          <div class="md:row-span-3 flex items-center justify-center">
            <svg
              class="h-24"
              width="577"
              height="159"
              viewBox="0 0 577 159"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M201.823 113.172V102.662C197.154 106.605 191.818 109.683 186.067 111.752C180.168 113.875 173.943 114.947 167.674 114.921C160.074 114.979 152.546 113.452 145.57 110.438C138.845 107.581 132.752 103.421 127.642 98.1984C122.532 92.9757 118.506 86.7934 115.797 80.0078C112.856 72.8196 111.369 65.1201 111.42 57.3538C111.362 49.6215 112.85 41.9554 115.797 34.8065C118.562 28.0381 122.614 21.8702 127.727 16.6443C132.889 11.446 139.024 7.31636 145.783 4.49176C158.502 -0.867877 172.73 -1.4511 185.845 2.84952C191.482 4.72097 196.797 7.45173 201.601 10.9453V1.75765H223.492V113.172H201.823ZM132.876 57.1408C132.876 68.0772 136.524 77.2678 143.821 84.7125C151.118 92.1573 160.093 95.8797 170.745 95.8797C176.725 95.94 182.652 94.7487 188.144 92.3822C193.317 90.1583 197.919 86.7892 201.601 82.5288V31.9303C197.841 27.7643 193.21 24.4762 188.037 22.2989C182.576 19.9513 176.689 18.7575 170.745 18.7925C160.093 18.7925 151.118 22.4409 143.821 29.7377C136.524 37.0346 132.876 46.1689 132.876 57.1408Z"
                fill="#263238"
              />
              <path
                d="M241.653 158.487V1.75594H263.322V12.4793C267.848 8.39611 273.128 5.23597 278.865 3.17626C292.122 -1.47285 306.637 -1.00431 319.566 4.49005C333.109 10.2601 343.814 21.1602 349.339 34.8048C352.286 41.9537 353.774 49.6198 353.716 57.3521C353.767 65.1184 352.28 72.8179 349.339 80.0061C345.102 90.3829 337.855 99.2536 328.532 105.476C319.208 111.698 308.236 114.987 297.028 114.919C290.967 114.929 284.943 113.97 279.185 112.078C273.559 110.253 268.272 107.515 263.535 103.974V158.487H241.653ZM294.622 19.0482C288.608 18.9987 282.649 20.1893 277.117 22.5457C271.899 24.7498 267.256 28.1206 263.544 32.3991V82.7934C267.221 87.0055 271.828 90.3031 277.001 92.4249C282.575 94.7781 288.572 95.9683 294.622 95.9224C305.274 95.9224 314.213 92.274 321.439 84.9771C328.665 77.6803 332.278 68.5608 332.278 57.6185C332.278 46.6762 328.629 37.5181 321.333 30.1444C317.883 26.5573 313.73 23.7204 309.134 21.8112C304.538 19.9019 299.598 18.9613 294.622 19.0482Z"
                fill="#263238"
              />
              <path
                d="M366.86 158.489V1.75765H388.529V12.481C393.054 8.39597 398.334 5.2356 404.073 3.17796C417.332 -1.47344 431.85 -1.00489 444.782 4.49176C458.325 10.2618 469.03 21.1619 474.555 34.8065C477.502 41.9554 478.99 49.6215 478.932 57.3538C478.983 65.1201 477.496 72.8196 474.555 80.0077C470.317 90.386 463.069 99.2577 453.744 105.48C444.419 111.702 433.445 114.991 422.235 114.921C416.177 114.931 410.156 113.972 404.401 112.08C398.774 110.257 393.487 107.519 388.751 103.975V158.489H366.86ZM419.873 19.0499C413.857 19.0004 407.895 20.191 402.359 22.5474C397.144 24.7519 392.504 28.1227 388.795 32.4008V82.7951C392.464 86.9855 397.056 90.2672 402.208 92.3822C407.796 94.7428 413.808 95.9332 419.873 95.8797C430.526 95.8797 439.465 92.2313 446.691 84.9345C453.916 77.6376 457.526 68.5181 457.52 57.5758C457.52 46.6335 453.872 37.4755 446.575 30.1017C443.124 26.522 438.971 23.6929 434.377 21.7913C429.782 19.8897 424.845 18.9564 419.873 19.0499Z"
                fill="#263238"
              />
              <path
                d="M481.564 98.773L493.379 84.3214C498.808 88.6084 504.815 92.1078 511.222 94.7163C517.1 97.0934 523.38 98.3199 529.721 98.3292C537.598 98.3292 544.019 96.7994 548.984 93.7399C553.949 90.6803 556.429 86.6649 556.423 81.6939C556.487 79.8915 556.123 78.0997 555.363 76.4644C554.602 74.8292 553.466 73.3969 552.047 72.2843C549.129 69.9526 544.605 68.3459 538.474 67.4641L518.332 64.6235C507.39 63.0138 499.105 59.6199 493.477 54.4417C487.849 49.2635 485.038 42.3691 485.044 33.7585C485.044 23.6979 489.056 15.5608 497.081 9.34691C505.105 3.13307 515.613 0.03205 528.603 0.0438859C536.51 0.00524136 544.376 1.18799 551.922 3.55028C559.599 6.04274 566.865 9.65623 573.484 14.2736L562.335 28.6453C556.83 24.8688 550.825 21.8809 544.492 19.7684C538.868 17.9689 532.999 17.0467 527.094 17.0343C520.377 17.0343 514.977 18.4191 510.893 21.1887C506.81 23.9583 504.765 27.6275 504.759 32.1961C504.759 36.2854 506.183 39.3864 509.029 41.4991C511.876 43.6118 516.583 45.1831 523.152 46.2128L543.267 49.0623C554.204 50.666 562.557 54.0955 568.327 59.3506C574.097 64.6058 576.988 71.5327 577 80.1315C577.024 84.9976 575.778 89.7859 573.387 94.0239C570.939 98.3785 567.577 102.152 563.533 105.085C559.04 108.314 554.051 110.791 548.762 112.417C542.813 114.277 536.611 115.199 530.378 115.151C521.194 115.221 512.059 113.815 503.321 110.988C495.316 108.433 487.913 104.278 481.564 98.773Z"
                fill="#263238"
              />
              <path
                d="M21.8905 23.6523H0V113.176H21.8905V23.6523Z"
                fill="#263238"
              />
              <path
                d="M77.0499 1.75781H43.7792C37.9735 1.75781 32.4055 4.06413 28.3003 8.16939C24.195 12.2747 21.8887 17.8426 21.8887 23.6483H77.0499V113.172H98.9404V1.75781H77.0499Z"
                fill="#263238"
              />
            </svg>
          </div>
          <a
            href="https://drive.google.com/drive/folders/1121wyrPQEMK-gOiughTIUMA26CWL8J0c?usp=sharing"
            target="_blank"
            download
            class="
              md:row-span-2 md:col-start-2 md:col-span-2
              flex
              h-full
              flex-col-reverse
              md:flex-row md:h-72
              cursor-pointer
              transition-shadow
              hover:shadow-lg
              focus:outline-none focus:ring-2
              ring-lightBlue ring-opacity-50
            "
          >
            <div class="md:w-1/2 bg-lightBlue flex flex-col justify-center p-6">
              <h3
                class="font-gothammedium text-mainBrand text-xl leading-tight"
              >
                {{ $t("press.media_kit") }}
              </h3>
              <p class="font-gothambook text-gray-600 text-sm mt-2">
                {{ $t("press.media_kit_date") }}
              </p>
            </div>
            <div class="h-56 md:h-full md:w-1/2">
              <img
                class="w-full h-full object-cover"
                alt="Media Kit"
                src="https://d3dx13u2x4z410.cloudfront.net/team-photo.jpg"
              />
            </div>
          </a>

          <router-link
            to="/team"
            class="
              md:row-span-2 md:col-start-2 md:col-span-2
              flex
              h-full
              flex-col
              md:flex-row md:h-72
              cursor-pointer
              transition-shadow
              hover:shadow-lg
              focus:outline-none focus:ring-2
              ring-yellowBrand ring-opacity-50
            "
          >
            <div class="w-full h-56 md:h-full md:w-1/2">
              <img
                class="w-full h-full object-cover"
                alt="the NAPPS team"
                src="https://d3dx13u2x4z410.cloudfront.net/team-photo.jpg"
              />
            </div>
            <div
              class="md:w-1/2 bg-yellow-500 flex flex-col justify-center p-6"
            >
              <h3
                class="font-gothammedium text-yellow-800 text-xl leading-tight"
              >
                {{ $t("press.leadership_team") }}
              </h3>
              <p class="font-gothambook text-yellow-700 text-sm mt-2">
                {{ $t("press.leadership_team_date") }}
              </p>
            </div>
          </router-link>

          <a
            target="_blank"
            href="https://docs.google.com/document/d/19GuUi-426VQrk2DNf4Ru3gi9XgbUOAsIHDfxbjfHnaI/edit?usp=sharing"
            download
            @click.prevent="downloadLetter()"
            class="
              founder-letter-box
              md:col-start-1
              bg-yellow-100
              p-6
              flex flex-col
              justify-center
              cursor-pointer
              transition-shadow
              hover:shadow-lg
              focus:outline-none focus:ring-2
              ring-yellow-500 ring-opacity-50
            "
          >
            <h3 class="font-gothammedium text-yellow-700 text-lg">
              Founder’s Letter, 2021
            </h3>
            <p class="font-gothambook text-yellow-600 text-sm mt-2">
              {{ $t("press.founders_letter_date") }}
            </p>
          </a>
        </div>
      </div>

      <div
        class="
          bg-mainBrand
          flex flex-col
          items-center
          text-white text-center
          px-5
        "
      >
        <svg
          class="m-16 w-24"
          width="143"
          height="145"
          viewBox="0 0 143 145"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M95.1191 0C82.3951 0.0498196 70.2101 5.14003 61.235 14.1551C52.2599 23.1701 47.227 35.3743 47.2395 48.0923H95.1191V144.277H142.999V0H95.1191Z"
            fill="white"
          />
          <path d="M47.2412 48.5195H0V144.279H47.2412V48.5195Z" fill="white" />
        </svg>
        <h2
          class="
            font-gothammedium
            text-2xl
            md:text-3xl
            max-w-5xl
            tracking-tight
          "
        >
          {{ $t("press.sale_methods") }}
        </h2>
        <p class="font-gothambook max-w-3xl mt-5 text-normal opacity-90">
          {{ $t("press.help_brands") }}
        </p>
        <a
          href="#"
          class="
            flex
            gap-5
            items-center
            m-5
            mb-16
            md:m-16
            p-5
            hover:bg-gray-700 hover:shadow-lg
            transition-all
            ring-gray-700 ring-opacity-50
            focus:outline-none focus:ring-2
          "
        >
          <svg
            class="w-8 h-8"
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="19.5"
              cy="19.5"
              r="18"
              stroke="white"
              stroke-width="3"
            />
            <path
              d="M11 17.5C10.1716 17.5 9.5 18.1716 9.5 19C9.5 19.8284 10.1716 20.5 11 20.5V17.5ZM28.5727 20.0607C29.1585 19.4749 29.1585 18.5251 28.5727 17.9393L19.0268 8.3934C18.441 7.80761 17.4913 7.80761 16.9055 8.3934C16.3197 8.97919 16.3197 9.92893 16.9055 10.5147L25.3908 19L16.9055 27.4853C16.3197 28.0711 16.3197 29.0208 16.9055 29.6066C17.4913 30.1924 18.441 30.1924 19.0268 29.6066L28.5727 20.0607ZM11 20.5H27.5121V17.5H11V20.5Z"
              fill="white"
            />
          </svg>

          <router-link to="/aboutus" class="font-gothammedium">{{
            $t("press.see_more")
          }}</router-link>
        </a>
      </div>

      <div
        class="
          flex flex-col
          md:flex-row
          gap-8
          md:gap-5
          px-5
          justify-around
          my-20
          container
          self-center
        "
      >
        <h3
          class="
            text-center
            md:text-left md:border-b-4
            border-yellow-500
            font-gothammedium
            text-4xl
          "
        >
          {{ $t("press.contact_us") }}
        </h3>
        <a
          href="mailto: press@napps.pt"
          class="
            py-2
            px-10
            border-2 border-yellow-500
            rounded-lg
            text-xl
            font-gothambold
            text-center
            hover:opacity-80
          "
        >
          press@napps.pt
        </a>
        <a
          href="tel: +351912049295"
          class="
            py-2
            px-10
            border-2 border-yellow-500
            rounded-lg
            text-xl
            font-gothambold
            text-center
            hover:opacity-80
          "
        >
          +351 912 049 295
        </a>
      </div>
    </div>

    <footer2 />
  </div>
</template>

<script>
import NavBar from "@/components/Navbar/Main.vue";
import Footer2 from "@/components/Footer/Main.vue";
import axios from "axios";

import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  components: {
    NavBar,
    Footer2,
    ScrollTrigger,
  },
  data() {
    return {
      fillBG: false,
    };
  },
  methods: {
    downloadLetter() {
      axios
      .get("https://nappscdn.s3.eu-west-3.amazonaws.com/Carta+dos+Fundadores.pdf", {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Carta-dos-Fundadores";
        link.click();
        URL.revokeObjectURL(link.href);
        })
      .catch(console.error);
    },
  },
  mounted() {
    ScrollTrigger.create({
      trigger: "#main-section",
      start: "top 6%",
      end: "bottom 130%",
      onEnter: () => {
        this.fillBG = true;
      },
      onEnterBack: () => {
        this.fillBG = false;
      },
    });
  },
  unmounted() {
    window.scrollTo(0, 0);
    ScrollTrigger.getAll().forEach((element) => {
      element.kill();
    });
  },
};
</script>

<style scoped>
.main-button {
  background-color: #263238;
  padding: 12px 40px;
  color: white;
  border-radius: 5px;
  font-family: GothamBook, "sans-serif";
  width: 320px;
  text-transform: uppercase;
}
.main-button:focus {
  outline: none;
}
.main-button:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 600px) {
  .grid-rows-4 {
    grid-template-rows: none !important;
  }
}
</style>


