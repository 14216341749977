<template>
  <div
    class="
      bg-mainBrand
      w-screen
      h-screen
      flex
      justify-center
      items-center
      text-center
    "
  >
    <div class="flex flex-col gap-6 md:w-1/2">
      <h4 class="font-gothambook text-white">Account Updates</h4>
      <div
        class="
          bg-white
          border-t-8 border-yellow-400
          text-mainBrand
          w-full
          py-12
          px-12
          md:py-24
          2xl:py-32
          rounded-b-md
        "
      >
        <transition name="fade" mode="out-in">
          <div v-if="!resubscribed">
            <h2
              v-if="!resubscribed"
              class="font-gothambold text-4xl 2xl:text-5xl"
            >
              You've been unsubscribed.
            </h2>
            <p v-if="!resubscribed" class="font-gothambook mt-2 2xl:text-2xl">
              We won't send you any more e-mails.
            </p>
          </div>

          <div v-else>
            <h2
              v-if="resubscribed"
              class="font-gothambold text-4xl 2xl:text-5xl"
            >
              Thank you!
            </h2>
            <p v-if="resubscribed" class="font-gothambook mt-2 2xl:text-2xl">
              You've been resubscribed.
            </p>
          </div>
        </transition>
      </div>
      <transition name="fade" mode="out-in">
        <div
          :class="{ 'fade-leave-to': resubscribed }"
          class="
            flex flex-col
            md:flex-row
            gap-4
            justify-around
            font-gothambook
            text-white
            items-center
          "
        >
          <p>Unsubscribe by accident?</p>
          <button
            @click="this.resubscribed = true"
            class="
              focus:outline-none
              hover:opacity-90
              text-yellow-400
              focus:border-yellow-300 focus:text-yellow-300
              font-gothammedium
              border-2 border-yellow-400
              py-2
              px-4
              rounded-md
            "
          >
            Re-subscribe
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resubscribed: false,
    };
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>