<template>
  <div class="flex flex-col bg-mainBrand">
    <nav-bar :fillBG="fillBG" :isTransparent="true" style="background: none" />

    <div
    id="main-section"
      class="w-full bg-mainBrand text-white p-10 md:p-20 pt-20 flex flex-col justify-end"
    >
      <div
        class="
          self-center
          bg-mainBrand
          flex
          justify-between
          md:h-48
          container
        "
      >
        <div class="self-end">
          <h1 class="font-gothambold text-4xl md:text-5xl w-48 md:w-auto">
            Pedro Pontes
          </h1>
          <p class="font-gothambook italic text-sm opacity-90">
            {{ $t("team.pedro_pontes") }}
          </p>
        </div>

        <div
          class="
            h-32
            w-36
            self-end
            transform
            md:translate-y-40 md:w-auto md:h-auto
            flex-shrink-0
          "
        >
          <img
            class="md:h-96 md:w-72 object-cover flex-shrink-0"
            src="@/assets/imgs/pedropontes.png"
            alt="Pedro Pontes Headshot"
          />
        </div>
      </div>
    </div>

    <div class="bg-gray-100 w-full p-10 md:p-20 pt-20 flex flex-col">
      <div
        class="
          self-center
          flex-shrink-0
          text-mainBrand
          flex flex-col
          md:flex-row
          justify-between
          bg-gray-100
          container
          md:py-10
        "
      >
        <div class="md:w-3/4">
          <h3 class="font-gothammedium text-2xl">BIOGRAPHY</h3>
          <p class="font-gothambook mt-5 md:mr-20 leading-8 opacity-90">
            A NAPPS iniciou quando dois amigos entenderam que havia no mercado a
            necessidade de uma aplicação com custos mais reduzidos e um maior
            apoio ao cliente – Que possibilitaria ao utilizador maior conversão,
            a longo prazo. Assim criamos uma plataforma que dá o poder a milhões
            de clientes em todo o mundo para iniciar e fazer crescer os seus
            negócios com a nossa tecnologia. Fundada em Fevereiro de 2021 e
            sediada em Portugal com escritórios no Porto, a NAPPS é 100%
            propriedade dos fundadores.
          </p>
        </div>
        <div class="md:w-72 mt-10 md:mt-0 flex-shrink-0">
          <a
            aria-label="Download Headshot"
            href="#"
            class="
              flex
              justify-between
              items-center
              p-4
              bg-white
              rounded-lg
              shadow
              hover:shadow-lg
              transition-shadow
              mb-5
            "
          >
            <div class="flex flex-col">
              <h1 class="font-gothambold">Headshot</h1>
              <p class="font-gothambook">JPG 2.83 MB</p>
            </div>
            <svg
              class="w-6 h-6"
              width="30"
              height="24"
              viewBox="0 0 30 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.6129 8.88C24.2229 3.91821 20.0604 0 15 0C10.6714 0 6.95571 2.81143 5.75679 6.91179C2.33679 8.09893 0 11.3389 0 15C0 19.7261 3.84536 23.5714 8.57143 23.5714H22.5C26.6357 23.5714 30 20.2071 30 16.0714C30 12.6975 27.7821 9.80143 24.6129 8.88ZM22.5 21.4286H8.57143C5.02607 21.4286 2.14286 18.5454 2.14286 15C2.14286 12.0943 4.10679 9.54214 6.91821 8.79429C7.30393 8.69143 7.60071 8.38286 7.68857 7.99393C8.44929 4.60393 11.5243 2.14286 15 2.14286C18.9579 2.14286 22.4957 5.27571 22.4957 9.73821C22.4957 10.2621 22.8739 10.7089 23.3904 10.7946C25.9789 11.2286 27.8571 13.4475 27.8571 16.0714C27.8571 19.0254 25.4539 21.4286 22.5 21.4286Z"
                fill="#263238"
              />
              <path
                d="M20.0432 11.0276C20.4621 11.4465 20.4621 12.1237 20.0432 12.5426C19.6243 12.9615 18.9471 12.9615 18.5282 12.5426L16.0714 10.0858V18.2137C16.0714 18.8051 15.5914 19.2851 15 19.2851C14.4086 19.2851 13.9286 18.8051 13.9286 18.2137V10.0858L11.4718 12.5426C11.0528 12.9615 10.3757 12.9615 9.95677 12.5426C9.53785 12.1237 9.53785 11.4465 9.95677 11.0276L14.2425 6.74188C14.6603 6.32402 15.3439 6.32723 15.7586 6.74188L20.0432 11.0276Z"
                fill="#263238"
              />
            </svg>
          </a>

          <a
            aria-label="Download Biography"
            href="#"
            class="
              flex
              justify-between
              items-center
              p-4
              bg-white
              rounded-lg
              shadow
              hover:shadow-lg
              transition-shadow
              mb-5
            "
          >
            <div class="flex flex-col">
              <h1 class="font-gothambold">Biography</h1>
              <p class="font-gothambook">PDF 100.83 KB</p>
            </div>
            <svg
              class="w-6 h-6"
              width="30"
              height="24"
              viewBox="0 0 30 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.6129 8.88C24.2229 3.91821 20.0604 0 15 0C10.6714 0 6.95571 2.81143 5.75679 6.91179C2.33679 8.09893 0 11.3389 0 15C0 19.7261 3.84536 23.5714 8.57143 23.5714H22.5C26.6357 23.5714 30 20.2071 30 16.0714C30 12.6975 27.7821 9.80143 24.6129 8.88ZM22.5 21.4286H8.57143C5.02607 21.4286 2.14286 18.5454 2.14286 15C2.14286 12.0943 4.10679 9.54214 6.91821 8.79429C7.30393 8.69143 7.60071 8.38286 7.68857 7.99393C8.44929 4.60393 11.5243 2.14286 15 2.14286C18.9579 2.14286 22.4957 5.27571 22.4957 9.73821C22.4957 10.2621 22.8739 10.7089 23.3904 10.7946C25.9789 11.2286 27.8571 13.4475 27.8571 16.0714C27.8571 19.0254 25.4539 21.4286 22.5 21.4286Z"
                fill="#263238"
              />
              <path
                d="M20.0432 11.0276C20.4621 11.4465 20.4621 12.1237 20.0432 12.5426C19.6243 12.9615 18.9471 12.9615 18.5282 12.5426L16.0714 10.0858V18.2137C16.0714 18.8051 15.5914 19.2851 15 19.2851C14.4086 19.2851 13.9286 18.8051 13.9286 18.2137V10.0858L11.4718 12.5426C11.0528 12.9615 10.3757 12.9615 9.95677 12.5426C9.53785 12.1237 9.53785 11.4465 9.95677 11.0276L14.2425 6.74188C14.6603 6.32402 15.3439 6.32723 15.7586 6.74188L20.0432 11.0276Z"
                fill="#263238"
              />
            </svg>
          </a>

          <a
            aria-label="Download Headshot and Biography"
            href="#"
            class="
              flex
              justify-between
              items-center
              p-4
              bg-white
              rounded-lg
              shadow
              hover:shadow-lg
              transition-shadow
              mb-5
            "
          >
            <div class="flex flex-col">
              <h1 class="font-gothambold">Headshot and Biography</h1>
              <p class="font-gothambook">JPG 2.83 MB</p>
            </div>
            <svg
              class="w-6 h-6"
              width="30"
              height="24"
              viewBox="0 0 30 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.6129 8.88C24.2229 3.91821 20.0604 0 15 0C10.6714 0 6.95571 2.81143 5.75679 6.91179C2.33679 8.09893 0 11.3389 0 15C0 19.7261 3.84536 23.5714 8.57143 23.5714H22.5C26.6357 23.5714 30 20.2071 30 16.0714C30 12.6975 27.7821 9.80143 24.6129 8.88ZM22.5 21.4286H8.57143C5.02607 21.4286 2.14286 18.5454 2.14286 15C2.14286 12.0943 4.10679 9.54214 6.91821 8.79429C7.30393 8.69143 7.60071 8.38286 7.68857 7.99393C8.44929 4.60393 11.5243 2.14286 15 2.14286C18.9579 2.14286 22.4957 5.27571 22.4957 9.73821C22.4957 10.2621 22.8739 10.7089 23.3904 10.7946C25.9789 11.2286 27.8571 13.4475 27.8571 16.0714C27.8571 19.0254 25.4539 21.4286 22.5 21.4286Z"
                fill="#263238"
              />
              <path
                d="M20.0432 11.0276C20.4621 11.4465 20.4621 12.1237 20.0432 12.5426C19.6243 12.9615 18.9471 12.9615 18.5282 12.5426L16.0714 10.0858V18.2137C16.0714 18.8051 15.5914 19.2851 15 19.2851C14.4086 19.2851 13.9286 18.8051 13.9286 18.2137V10.0858L11.4718 12.5426C11.0528 12.9615 10.3757 12.9615 9.95677 12.5426C9.53785 12.1237 9.53785 11.4465 9.95677 11.0276L14.2425 6.74188C14.6603 6.32402 15.3439 6.32723 15.7586 6.74188L20.0432 11.0276Z"
                fill="#263238"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>

    <footer2 class="bg-white" />
  </div>
</template>

<script>
import NavBar from "@/components/Navbar/Main.vue";
import Footer2 from "@/components/Footer/Main.vue";

import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  components: {
    NavBar,
    Footer2,
    ScrollTrigger,
  },
  data() {
    return {
      fillBG: false,
    };
  },
  mounted() {
    ScrollTrigger.create({
      trigger: "#main-section",
      start: "top 6%",
      end: "bottom 130%",
      onEnter: () => {
        this.fillBG = true;
      },
      onEnterBack: () => {
        this.fillBG = false;
      },
    });
  },
  unmounted() {
    window.scrollTo(0, 0);
    ScrollTrigger.getAll().forEach((element) => {
      element.kill();
    });
  },
};
</script>