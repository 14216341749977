<template>
  <div class="h-screen review-bg">
    <div class="square one"></div>
    <div class="square two"></div>
    <div class="square three"></div>
    <div class="square four"></div>
    <div class="square five"></div>
    <div class="square six"></div>
    <div class="square seven"></div>
    <div class="square eight"></div>
    <div class="square nine"></div>
    <div class="square ten"></div>
    <div class="square eleven"></div>
    <div class="square twelve"></div>
    <div class="square fourteen"></div>
    <div class="square fifteen"></div>
    <div class="square sixteen"></div>
    <div class="square seventeen"></div>
    <div class="square heighteen"></div>
    <div class="inner-container h-screen">
      <div class="title-line">
        <span>How happy are you with</span>
        <napps-blue class="logo-napps" />
        <span>?</span>
      </div>
      <div class="star-line">
        <star class="star" />
        <star class="star" />
        <star class="star" />
        <star class="star" />
        <star class="star" />
      </div>
      <div class="button-line">
        <button class="review-button">EVALUATION</button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Footer2 from "@/components/Footer/Main.vue";
import NavSupport from "@/components/Navbar/Support.vue";
import axios from "axios";
import { useStore } from "@/store";
import NappsBlue from "@/components/Icons/NappsBlue.vue";
import Star from "../components/Icons/Star.vue";

export default defineComponent({
  components: {
    Footer2,
    NavSupport,
    NappsBlue,
    Star,
  },
  unmounted() {
    window.scrollTo(0, 0);
  },
  mounted() {
    const store = useStore();
    if (store.getters["support/checkEmpty"]) {
      store.dispatch("support/getCategories");
    }
  },
  computed: {
    categories() {
      return useStore().getters["support/categories"];
    },
  },
  setup() {},
});
</script>

<style scoped>
.review-bg {
  width: 100%;
  background-color: #284452;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.inner-container {
  padding: 100px;
  background-color: white;
  border-radius: 20px;
  width: 80%;
  height: 80%;
}
.title-line {
  color: #284452;
  font-family: GothamMedium;
  font-size: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 33%;
}
.logo-napps {
  max-width: 150px;
  padding-top: 7px;
  padding-left: 15px;
  padding-right: 5px;
}
.star-line {
  height: 33%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.star {
  height: 70px;
  width: auto;
}
.review-button {
  background-color: #284452;
  padding: 12px;
  color: white;
  border-radius: 5px;
  font-family: GothamBook, "sans-serif";
  margin-right: 25px;
  width: 33%;
  text-transform: uppercase;
}
.button-line {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 33%;
}

@media only screen and (max-width: 1024px) {
  .inner-container {
    padding: 0px;
  }

  .title-line {
    font-size: 35px;
    width: 100%;
    height: 40%;
  }

  .star-line {
    height: 20%;
  }

  .button-line {
    height: 40%;
  }

  .logo-napps {
    max-width: 140px;
    padding-top: 14px;
    padding-left: 10px;
    padding-right: 5px;
  }
  .review-button {
    width: 50%;
    margin-right: 0px;
  }
}

.square {
  border: 1px solid #ffffffa2;
  border-radius: 4px;
  position: absolute;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 1s;
  opacity: 1;
}
.one {
  height: 80px;
  width: 80px;
  top: 7%;
  left: 19%;
  transform: rotate(120deg);
}
.two {
  height: 80px;
  width: 80px;
  top: 89%;
  left: 5%;
  transform: rotate(280deg);
}
.three {
  height: 70px;
  width: 70px;
  top: 82%;
  left: 89%;
  transform: rotate(220deg);
}
.four {
  height: 60px;
  width: 60px;
  top: 89%;
  left: 67%;
  transform: rotate(40deg);
}
.five {
  height: 60px;
  width: 60px;
  top: 93%;
  left: 32%;
  transform: rotate(130deg);
}
.six {
  height: 85px;
  width: 85px;
  top: 43%;
  left: 6%;
  transform: rotate(230deg);
}
.seven {
  height: 25px;
  width: 25px;
  top: 33%;
  left: 94%;
  transform: rotate(280deg);
}
.eight {
  height: 15px;
  width: 15px;
  top: 7%;
  left: 33%;
  transform: rotate(280deg);
  border-radius: 4px;
}
.ten {
  height: 9px;
  width: 9px;
  top: 8%;
  left: 53%;
  transform: rotate(280deg);
  border-radius: 2px;
}
.eleven {
  height: 9px;
  width: 9px;
  top: 3%;
  left: 67%;
  transform: rotate(280deg);
  border-radius: 2px;
}
.twelve {
  height: 9px;
  width: 9px;
  top: 5%;
  left: 7%;
  transform: rotate(280deg);
  border-radius: 2px;
}
.fourteen {
  height: 9px;
  width: 9px;
  top: 4%;
  left: 87%;
  transform: rotate(280deg);
  border-radius: 2px;
}
</style>