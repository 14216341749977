<template>
  <!-- <nav-bar /> -->
  <div>
    <div class="flex flex-row h-screen items-center first-box">
      <nav-bar :fillBG="fillBG" />
      <div class="first-section mr-10">
        <div class="h-1 bg-yellow w-24 opacity-75 rounded ml-20"></div>
        <h1
          class="
            title-contact
            text-6xl text-black
            font-extrabold
            ml-20
            mt-4
            mr-4
          "
        >
          {{$t("contact.title")}}
        </h1>
        <arrow-contact class="arrow-contact w-full" />
      </div>
      <div class="second-section mr-20 ml-8">
        <div  :class="{'contact-fixed': contacted}" class="contact-form flex-row">
          <div v-if="!contacted">
            <div class="col-contact flex">
              <div class="fqZrOB left">
                <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
                  {{$t("contact.full_name_label")}}
                </p>
                <p
                  style="margin-top: -3px; font-size: 15px; font-weight: 300"
                  class="jAWKuj"
                ></p>
                <div class="liAdpv">
                  <div
                    :style="v$.fullName.$error ? 'border-color: red' : ''"
                    class="jsBTvB"
                  >
                    <input
                      v-model="v$.fullName.$model"
                      class="input-style"
                      name="name"
                      :placeholder="$t('contact.full_name_placeholder')"
                      style="font-size: 18px"
                    />
                  </div>
                </div>
                <p
                  v-if="v$.fullName.$error"
                  class="text-left text-sm text-error"
                >
                  {{ $t("contact.error_required_fullName") }}
                </p>
              </div>
              <div class="fqZrOB">
                <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
                  {{$t("contact.country_label")}}
                </p>
                <p
                  style="margin-top: -3px; font-size: 15px; font-weight: 300"
                  class="jAWKuj"
                ></p>
                <div class="liAdpv">
                  <div
                    :style="v$.country.$error ? 'border-color: red' : ''"
                    class="jsBTvB"
                  >
                    <input
                      v-model="v$.country.$model"
                      class="input-style"
                      name="country"
                      :placeholder="$t('contact.country_placeholder')"
                      style="font-size: 18px"
                    />
                  </div>
                </div>
                <p
                  v-if="v$.country.$error"
                  class="text-left text-sm text-error"
                >
                  {{ $t("contact.error_required_country") }}
                </p>
              </div>
            </div>
            <div class="col-contact flex">
              <div class="fqZrOB left">
                <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
                  {{$t("contact.email_label")}}
                </p>
                <p
                  style="margin-top: -3px; font-size: 15px; font-weight: 300"
                  class="jAWKuj"
                ></p>
                <div class="liAdpv">
                  <div
                    :style="v$.email.$error ? 'border-color: red' : ''"
                    class="jsBTvB"
                  >
                    <input
                      v-model="v$.email.$model"
                      class="input-style"
                      name="email"
                      :placeholder="$t('contact.email_placeholder')"
                      style="font-size: 18px"
                    />
                  </div>
                </div>
                <p v-if="v$.email.$error" class="text-left text-sm text-error">
                  {{ $t("contact.error_required_email") }}
                </p>
              </div>
              <div class="fqZrOB">
                <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
                  {{$t("contact.phone_label")}}
                </p>
                <p
                  style="margin-top: -3px; font-size: 15px; font-weight: 300"
                  class="jAWKuj"
                ></p>
                <div class="liAdpv">
                  <div
                    :style="v$.phoneNumber.$error ? 'border-color: red' : ''"
                    class="jsBTvB"
                  >
                    <input
                      v-model="v$.phoneNumber.$model"
                      class="input-style"
                      name="phone"
                      :placeholder="$t('contact.phone_placeholder')"
                      style="font-size: 18px"
                    />
                  </div>
                </div>
                <p
                  v-if="v$.phoneNumber.$error"
                  class="text-left text-sm text-error"
                >
                  {{ $t("contact.error_required_phone") }}
                </p>
              </div>
            </div>
            <div class="col-contact flex">
              <div class="fqZrOB left">
                <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
                  {{$t("contact.company_label")}}
                </p>
                <p
                  style="margin-top: -3px; font-size: 15px; font-weight: 300"
                  class="jAWKuj"
                ></p>
                <div class="liAdpv">
                  <div
                    :style="v$.company.$error ? 'border-color: red' : ''"
                    class="jsBTvB"
                  >
                    <input
                      v-model="v$.company.$model"
                      class="input-style"
                      name="company"
                      :placeholder="$t('contact.company_placeholder')"
                      style="font-size: 18px"
                    />
                  </div>
                </div>
                <p
                  v-if="v$.company.$error"
                  class="text-left text-sm text-error"
                >
                  {{ $t("contact.error_required_company") }}
                </p>
              </div>
              <div class="fqZrOB">
                <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
                 {{$t("contact.storeUrl_label")}}
                </p>
                <p
                  style="margin-top: -3px; font-size: 15px; font-weight: 300"
                  class="jAWKuj"
                ></p>
                <div class="liAdpv">
                  <div
                    :style="v$.storeUrl.$error ? 'border-color: red' : ''"
                    class="jsBTvB"
                  >
                    <input
                      v-model="v$.storeUrl.$model"
                      class="input-style"
                      name="storeUrl"
                      :placeholder="$t('contact.storeUrl_placeholder')"
                      style="font-size: 18px"
                    />
                  </div>
                </div>
                <p
                  v-if="v$.storeUrl.$error"
                  class="text-left text-sm text-error"
                >
                  {{ $t("contact.error_required_storeURL") }}
                </p>
              </div>
            </div>
            <p v-if="errorMessage" style="margin-left: 30px; margin-top: 10px;" class="text-left text-sm text-error">
              {{ errorMessage }}
            </p>
            <button
              v-on:click="contactUs"
              style="background-color: #263238"
              class="
                block
                text-lg
                uppercase
                mx-auto
                shadow
                font-semibold
                focus:shadow-outline focus:outline-none
                text-white
                py-3
                px-10
                rounded
                main-button
                anim-hidden
                mt-6
                mb-6
              "
            >
              <spinner v-if="showLoading" />
              {{$t("contact.button")}}
            </button>
          </div>
          <div class="contactReceived" v-else>
            <h2>{{$t("contact.title_contact_done")}}</h2>
            <p>{{$t("contact.message_contact_done")}}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      style="position: relative"
      class="
        flex flex-row
        second-box
        h-screen
        items-center
        justify-items-center
      "
    >
      <div style="width: 100%">
        <contact-box style="margin: auto" width="60%" />
      </div>
      <div>
        <h1
          style="color: #3e495d; width: 90%"
          class="title-contact text-6xl text-black font-extrabold title-chat"
        >
         {{$t("contact.sub_title")}}
        </h1>
      </div>
      <div
        style="position: absolute; top: 0%; left: 0px"
        class="h-1/2 first-bg"
      ></div>
      <div
        style="position: absolute; top: 50%; left: 0px"
        class="h-1/2 second-bg"
      ></div>
    </div>
    <footer2 />
  </div>
</template>
<script>
import { reactive, defineComponent, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { useI18n } from 'vue-i18n'
import axios from "axios";
import NavBar from "@/components/Navbar/Main.vue";
import Logo from "@/components/Icons/Logo.vue";
import SpoilerAlert from "@/components/About/Spoiler_Alert.vue";
import ArrowContact from "../components/Icons/ArrowContact.vue";
import ContactBox from "../components/Icons/ContactBox.vue";
import Footer2 from "@/components/Footer/Main.vue";

export default defineComponent({
  components: {
    NavBar,
    SpoilerAlert,
    Logo,
    ArrowContact,
    ContactBox,
    Footer2,
  },
  data() {
    return {
      tl: null,
      animated: false,
      fillBG: false,
    };
  },
  methods: {},
  unmounted() {
    window.scrollTo(0, 0);
    ScrollTrigger.getAll().forEach((element) => {
      element.kill();
    });
  },
  mounted() {
    ScrollTrigger.create({
      trigger: ".first-box",
      start: "top -10%",
      end: "bottom 150%",
      onEnter: () => {
        this.fillBG = true;
      },
      onEnterBack: () => {
        this.fillBG = false;
      },
    });
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })

    const state = reactive({
      fullName: "",
      country: "",
      email: "",
      phoneNumber: "",
      company: "",
      storeUrl: "",
    });

    const errorMessage = ref(null);
    const showLoading = ref(false);
    const contacted = ref(false);

    // Rules for validation
    const rules = {
      fullName: { required },
      country: { required },
      email: { required, email },
      phoneNumber: { required },
      company: { required },
      storeUrl: { required },
    };

    const v$ = useVuelidate(rules, state, { $lazy: true });
    const contactUs = async () => {
      v$.value.$touch();
      const isFormCorrect = await v$.value.$validate();
      if (!isFormCorrect) {
        return;
      }
      showLoading.value = true;
      axios
        .post(window.nappmaster + "/contact", state)
        .then(() => {
          contacted.value = true;
          showLoading.value = false;
        })
        .catch(function (error) {
          showLoading.value = false;
          if (error.response) {
            const response = error.response.data;
            switch (response.code) {
              case 0:
                errorMessage.value = t('startnow.error_server');
                break;
              case 1:
                errorMessage.value =
                  t('startnow.https_error')
                break;
              case 2:
                errorMessage.value =
                  t('startnow.already_account_error')
                break;
              case 3:
                errorMessage.value = t('startnow.invalid_url')
                break;
              case 5:
                errorMessage.value =
                  t('startnow.domain_error')
                break;
            }
          }
        });
    };

    return { state, v$, contactUs, errorMessage, showLoading, contacted };
  },
});
</script>


<style scoped>

.col-contact {
  flex-direction: row;
}

.bg-yellow {
  background-color: #fbbc04;
}
.first-bg {
  z-index: -1;
  background: linear-gradient(
    to bottom,
    #b0cbd800 0%,
    hsla(200, 34%, 77%, 0.65) 100%
  );
  width: 100%;
}

.second-bg {
  z-index: -1;
  background: linear-gradient(
    to bottom,
    hsla(200, 34%, 77%, 0.65) 0%,
    #b0cbd800 100%
  );
  width: 100%;
}

input:-internal-autofill-selected {
  background-color: white !important;
}

.title-contact {
  font-family: GothamBook, "sans-serif";
}

.first-section {
  width: 50%;
}

.second-section {
  width: 50%;
}

.arrow-contact {
}

.contact-form {
  border: 1px solid #b0cbd8;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.contact-fixed {
  height: 560px;
}

input {
  padding: 10px 20px;
  --tw-ring-color: white !important;
}
input:focus-visible {
  outline: none !important;
}
input:focus {
  outline: none !important;
}

.fqZrOB {
  min-height: 80px;
  background: white;
  width: 100%;
  margin: 30px;
  margin-bottom: 0px;
}
.ysiiV {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 26px;
  font-family: GothamBook;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 3px;
  color: rgb(0, 0, 0);
  text-align: left;
}
.jAWKuj {
  -webkit-font-smoothing: antialiased;
  line-height: 26px;
  color: rgb(0, 0, 0);
  font-family: SofiaPro;
  font-style: normal;
  margin-bottom: 5px;
  margin-top: -3px;
  font-size: 15px;
  font-weight: 300;
}
.liAdpv {
  position: relative;
}
.jsBTvB {
  position: relative;
  width: 100%;
}
.jsBTvB {
  border-radius: 3px;
  border: 1px solid rgb(186, 196, 224);
  background: rgb(252, 254, 255);
  letter-spacing: 0.25px;
  color: rgb(0, 0, 0);
  width: 100%;
  font-family: SofiaPro, -apple-system, BlinkMacSystemFont, Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: border 200ms ease 0s;
  text-align: left;
}
.input-style {
  width: 100%;
}

.text-error {
  color: red;
  margin-top: 5px;
  font-family: GothamBook;
}

.contactReceived {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.contactReceived > h2 {
  font-family: GothamMedium;
  font-size: 35px;
  color: #263238;
}

.contactReceived > p {
  font-family: GothamBook;
  font-size: 22px;
}

.title-chat {
  line-height: 75px;
}

@media only screen and (max-width: 1440px){
  .title-contact {
    font-size: 40px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 1024px) {
  .col-contact {
    flex-direction: column;
  }
  .contact-form {
    flex-direction: column;
    height: unset;
  }
  .first-box {
    flex-direction: column;
    height: fit-content;
  }
  .second-box {
    flex-direction: column;
    height: fit-content;
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .second-box > div > svg {
    height: auto;
    margin-bottom: 50px !important;
  }
  .first-section {
    width: 90%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
  }
  .second-section {
    margin-right: 20px;
    margin-left: 20px;
    width: 90%;
    margin-top: 20px;
  }
  .first-section > svg {
    display: none;
  }
  .first-section > div {
    margin-left: 0px;
  }
  .title-contact {
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .title-chat {
    width: 100% !important;
    margin-right: 0px;
    text-align: center;
  }
}

@media only screen and (max-width: 760px) {
  .title-contact {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 35px;
  }

  .first-section > div {
    margin-left: 0px;
  }
  .contact-form {
    margin-top: 20px;
  }
  .contact-form > div {
    flex-direction: column;
  }
  .fqZrOB {
    width: 90%;
    margin: 15px;
  }
  .first-bg {
    display: none;
  }
  .second-bg {
    display: none;
  }
  .title-chat {
    margin-right: 0px;
    text-align: center;
    width: 100% !important;
    line-height: 50px;
    padding: 0px 20px;
  }
}
</style>
